import React, { useEffect, useState } from 'react';

import { Dialog, Form } from '@gumtree/ui-library/src';

import { useDispatch } from 'react-redux';

import { trackGtmEvent } from '@gumtree/shared/src/util/gtm-tracker';

import {
    AlternativeText,
    BackgroundImage,
    Content,
    ContentWrapper,
    Root,
    Title,
} from './index.style';
import { setShowPopUpPrompt } from '../../reducers/common/location-prompt';
import { submitSearchHandler } from '../common/event-handlers';
import LocationSearch from '../common/location-search';

const PopUp = () => {
    const dispatch = useDispatch();

    const [location, setLocation] = useState('');
    const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

    useEffect(() => {
        trackGtmEvent({ action: 'LocationPromptPopUpShown' }).then();
    }, []);

    return (
        <Root>
            <Dialog
                isOpen
                onClose={(e) => {
                    const isClickOnCross = (e.target as HTMLElement).classList.contains('icon--clear');
                    const isClickOnOutside = (e.target as HTMLElement).classList.contains('dialog-container');

                    if (isClickOnCross) {
                        trackGtmEvent({ action: 'LocationPromptPopUpClose' }).then();
                    }
                    if (isClickOnOutside) {
                        trackGtmEvent({ action: 'LocationPromptPopUpBypass' }).then();
                    }

                    dispatch(setShowPopUpPrompt(false));
                }}
                className="filter-dialog--location-prompt-popup"
                closeIconType="clear"
                scrollStopper
            >
                <ContentWrapper>
                    <Content>
                        <Form
                            onSubmit={() => {
                                submitSearchHandler(
                                    location,
                                    setIsSearchSubmitted,
                                    'LocationPromptPopUpSubmit'
                                ).then();
                            }}
                        >
                            <Title>Find buyers and sellers in your community</Title>
                            <LocationSearch
                                isSearchSubmitted={isSearchSubmitted}
                                setIsSearchSubmitted={setIsSearchSubmitted}
                                location={location}
                                setLocation={setLocation}
                                submitTrackingEventAction="LocationPromptPopUpSubmit"
                            />
                            <AlternativeText>
                                <span>or </span>
                                <a
                                    href="/#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        trackGtmEvent({
                                            action: 'LocationPromptPopUpContinueClick',
                                        }).then();
                                        dispatch(setShowPopUpPrompt(false));
                                    }}
                                >
                                    continue
                                </a>
                            </AlternativeText>
                        </Form>
                    </Content>
                    <BackgroundImage />
                </ContentWrapper>
            </Dialog>
        </Root>
    );
};

export default PopUp;
