import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PostAdButton, Icon, Button, Form } from '@gumtree/ui-library/src';
import { trackGtmEvent } from '@gumtree/shared/src/util/gtm-tracker';

import { ExperimentNames } from '@gumtree/shared/src/model/experiment';
import {
    AlternativeText,
    BackgroundImage,
    ButtonGroup,
    Content,
    ContentWrapper,
    Header,
    Overlay,
    Title,
    Logo,
} from './index.style';
import type { ShellState } from '../../reducers/common';
import { setShowFullScreenOverlay } from '../../reducers/common/location-prompt';
import { submitSearchHandler } from '../common/event-handlers';
import LocationSearch from '../common/location-search';

export const loginButtonClickHandler = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    baseConfig: ShellState['baseConfig'],
    inExperimentGTC1815: boolean
) => {
    e.preventDefault();
    await Promise.all([
        trackGtmEvent({ action: 'HeaderLoginLink' }),
        trackGtmEvent({ action: 'HeaderLoginSelect' }),
    ]);
    inExperimentGTC1815 ? window.location.assign(`${baseConfig.buyerUrl}/login`) : window.location.assign(baseConfig.loginUrl);
};

export const postAdButtonClickHandler = async (baseConfig: ShellState['baseConfig']) => {
    await trackGtmEvent({
        action: 'PostAdBegin',
        label: 'PostAdHeader',
        timeout: 500,
    });
    window.location.assign(`${baseConfig.bffUrl}/postad/category`);
};

export const FullScreenPrompt: React.FC = () => {
    const dispatch = useDispatch();
    const { baseConfig, experiments } = useSelector((state: ShellState) => ({
        baseConfig: state.baseConfig,
        experiments: state.experiments
    }));

    const inExperimentGTC1815 = experiments?.[ExperimentNames.REGISTER_LOGIN_BFF] === 'B';

    const [location, setLocation] = useState('');
    const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

    useEffect(() => {
        trackGtmEvent({ action: 'LocationPromptFullScreenShown' }).then();
    }, []);

    return (
        <Overlay>
            <Header>
                <Logo />
                <ButtonGroup>
                    <PostAdButton
                        data-testid="post-ad-button"
                        label="Sell"
                        onClick={() => {
                            postAdButtonClickHandler(baseConfig).then();
                        }}
                        bottomLabel
                    />
                    <Button
                        dark
                        icon={<Icon type="person" />}
                        label="Login/Register"
                        href={inExperimentGTC1815 ? `${baseConfig.buyerUrl}/login` : baseConfig.loginUrl}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            loginButtonClickHandler(e, baseConfig, inExperimentGTC1815).then();
                        }}
                        bottomLabel
                    />
                </ButtonGroup>
            </Header>
            <ContentWrapper>
                <Content>
                    <Form
                        onSubmit={() => {
                            submitSearchHandler(
                                location,
                                setIsSearchSubmitted,
                                'LocationPromptFullScreenContinueClick'
                            ).then();
                        }}
                    >
                        <Title>Find buyers and sellers in your community</Title>
                        <LocationSearch
                            isSearchSubmitted={isSearchSubmitted}
                            setIsSearchSubmitted={setIsSearchSubmitted}
                            location={location}
                            setLocation={setLocation}
                            submitTrackingEventAction="LocationPromptFullScreenContinueClick"
                        />
                        <AlternativeText>
                            <span>or </span>
                            <a
                                href="/#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    trackGtmEvent({
                                        action: 'LocationPromptFullScreenContinueClick',
                                    }).then();
                                    dispatch(setShowFullScreenOverlay(false));
                                }}
                            >
                                continue
                            </a>
                        </AlternativeText>
                    </Form>
                </Content>
                <BackgroundImage />
            </ContentWrapper>
        </Overlay>
    );
};

export default FullScreenPrompt;
