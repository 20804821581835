import React from 'react';
import { css } from '@emotion/react';
import {
    breakpoints,
    colors,
    fontSizes,
    fontWeights,
    gutterSizes,
    lineHeights,
    mediaQuery,
} from '@gumtree/ui-library/src/base/theme';

export const layoutBoundary = breakpoints.medium;
export const StyledLocationField = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        position: relative;
        display: flex;
        margin-bottom: ${gutterSizes.xlarge};

        .location-input-icon {
            position: absolute;
            left: ${gutterSizes.medium};
            top: ${gutterSizes.medium};
            color: ${colors.bark80};
            font-size: ${fontSizes.xlarge};
            z-index: 11;
        }

        .type-ahead-options {
            margin-top: ${gutterSizes.xsmall};
        }

        .form-element {
            .input-clear {
                color: ${colors.bark80};
            }
        }

        & input {
            padding: 0 ${gutterSizes.xxxlarge};
            border-radius: 8px 0 0 8px;
        }

        & input:focus {
            border: none;
        }

        & input:focus:focus {
            border: none;
        }

        .button {
            width: 48px;
            border-radius: 0 8px 8px 0;

            svg {
                margin-left: 0;
            }
        }
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const AlternativeText = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        line-height: ${lineHeights.small};
        font-size: ${fontSizes.base};
        font-weight: ${fontWeights.light};
        color: ${colors.white};

        a {
            color: ${colors.leaf};
            font-weight: ${fontWeights.bold};
            text-decoration: underline;
        }
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const armchairStyles = css`
    position: absolute;

    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and crop if necessary */
    object-position: center;

    transform: rotateY(180deg);
`;

export const commonCurveVerticalStyles = css`
    ${mediaQuery.until(layoutBoundary)} {
        display: none;
    }

    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
`;

export const commonTitleStyles = css`
    color: ${colors.white};
    font-weight: ${fontWeights.bold};
    margin-bottom: ${gutterSizes.xxlarge};
`;
