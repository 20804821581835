 
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { trackGA4Async } from '@gumtree/shared/src/util/ga4-shared';
import { getDomainFromUrl } from '@gumtree/shared/src/util/url-service';
import { Container, Grid, Icon } from '@gumtree/ui-library';
import {
    mediaQuery,
    breakpoints,
    fontSizes,
    fontWeights,
} from '@gumtree/ui-library/src/base/theme';
import type { ShellUseDispatch } from '../reducers/common';

import { trackAndNavigate } from '../common/common-actions';
import SubNavPanelSection from './sub-nav-panel-section';
import SubNavPanelSectionMotors from './sub-nav-panel-section-motors';
import { onClickFunction } from './sub-nav-panel-section-item';
import { PanelContainer } from './panel-container/panel-container';

const PanelHeader = styled.div`
    padding: 14px 0;
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.base};

    position: fixed;
    top: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    width: 100%;

    ${mediaQuery.from(breakpoints.small)} {
        display: none;
    }
`;

const Title = styled.div`
    display: inline-block;
`;

const StyledIcon = styled(Icon)<{ tabIndex: number; role: string }>`
    float: right;

    &:hover {
        cursor: pointer;
    }
`;

interface Props {
    title?: string;
    seoName: string;
    isActive: boolean;
    items: Array<any> | undefined;
    onClose?: EventListener;
}

const SubNavPanel = ({ title, seoName, isActive, items = [], onClose, ...props }: Props) => {
    const maxItemsInColumn = Math.ceil(items.length / 4);
    const columnOfItems: Array<any> = [];
    const dispatch = useDispatch() as ShellUseDispatch;

    items.forEach((item, i) => {
        if (i % maxItemsInColumn === 0) {
            columnOfItems.push([item]);
        } else {
            columnOfItems[columnOfItems.length - 1].push(item);
        }
    });

    const closePanelHandler = (e) => {
        onClose && onClose(e);
    };

    const onItemClickHandler: onClickFunction = (e, { href, itemLabel }, sectionTitle) => {
        e.preventDefault();

        trackGA4Async<GA4.ClickNavigationMenu>({
            event: 'click_navigation_menu',
            linkDomain: getDomainFromUrl(href),
            linkText: itemLabel,
            linkURL: href,
            navigationCategory: seoName as any,
            navigationHeader: sectionTitle,
            navigationHierarchy: 'sub category',
        }).then(() => {
            dispatch(
                trackAndNavigate(
                    'NavL2CategoryClick',
                    href,
                    `${title}${itemLabel ? `:${itemLabel}` : ''}`
                )
            );
        });
    };

    return (
        <PanelContainer isActive={isActive} {...props} data-testid="sub-nav-panel">
            <PanelHeader>
                <Container>
                    <Title>{title}</Title>
                    <StyledIcon
                        type="clear"
                        onClick={closePanelHandler}
                        tabIndex={0}
                        role="button"
                        aria-label="Close"
                    />
                </Container>
            </PanelHeader>
            <Container>
                <Grid container>
                    <SubNavPanelSection
                        title="Browse by"
                        items={items}
                        numberOfItemColumns={['cars-vans-motorbikes'].includes(seoName) ? 2 : 4}
                        col={12}
                        colL={['cars-vans-motorbikes'].includes(seoName) ? 7 : 12}
                        colXl={['cars-vans-motorbikes'].includes(seoName) ? 6 : 12}
                        colXxl={['cars-vans-motorbikes'].includes(seoName) ? 5 : 12}
                        onItemClick={onItemClickHandler}
                    />

                    {['cars-vans-motorbikes'].includes(seoName) && (
                        <SubNavPanelSectionMotors onItemClick={onItemClickHandler} />
                    )}
                </Grid>
            </Container>
        </PanelContainer>
    );
};

export default memo(SubNavPanel, ({ isActive: prevIsActive }, { isActive: nextIsActive }) => {
    return prevIsActive === nextIsActive;
});
