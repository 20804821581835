import classnames from 'classnames';
import React, { ReactNode, FC } from 'react';
import { useTheme } from '@emotion/react';
import Spinner from '../spinner/spinner';
import { buttonCss } from './button.style';

/** Base button component used with different themes and can have icons too */
const Button: FC<Props> = ({
    bottomLabel,
    className,
    color,
    dark,
    disabled,
    display,
    fullWidth,
    href,
    shouldOpenNewTab,
    icon,
    secondIcon,
    iconPosition,
    label,
    noFollow,
    onClick,
    type,
    isLoading,
    loadingText,
    tabIndex,
    ...props
}) => {
    const theme = useTheme();
    const themedStyles = buttonCss(theme);
    const Elem = href ? 'a' : 'button';

    const classes = classnames(
        {
            button: true,
            [`button--${display}`]: display,
            'button--dark': dark,
            'button--bottom-label': bottomLabel,
            'button--full-width': fullWidth,
            'button--only-icon': icon && !label,
            'button--right-icon': iconPosition === 'right',
            [`button--color-${color}`]: color,
        },
        className
    );

    return (
        <Elem
            className={classes}
            disabled={disabled}
            href={href}
            rel={`${noFollow ? 'nofollow ' : ''}noopener noreferrer`}
            {...(shouldOpenNewTab && href ? { target: '_blank' } : {})}
            onClick={onClick}
            type={type || undefined}
            tabIndex={tabIndex}
            {...props}
            css={themedStyles}
        >
            {(!iconPosition || iconPosition === 'left') && icon}
            {isLoading ? loadingText : label}
            {iconPosition === 'right' && icon}
            {secondIcon}
            {isLoading && <Spinner />}
        </Elem>
    );
};

export interface Props extends React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
    bottomLabel?: boolean;
    className?: string;
    color?: string;
    dark?: boolean;
    disabled?: boolean;
    display?: 'primary' | 'secondary' | 'tertiary' | 'quarternary' | 'GTALL-26629-primary' | null;
    fullWidth?: boolean;
    href?: string;
    icon?: ReactNode | null;
    id?: string;
    secondIcon?: ReactNode | null;
    iconPosition?: 'left' | 'right';
    label?: string | JSX.Element;
    noFollow?: boolean;
    onClick?: (event?: any) => void;
    type?: 'button' | 'submit' | 'reset' | null;
    shouldOpenNewTab?: boolean;
    isLoading?: boolean;
    loadingText?: string;
    tabIndex?: number;
}

Button.defaultProps = {
    bottomLabel: false,
    className: '',
    color: '',
    dark: false,
    disabled: false,
    display: null,
    fullWidth: false,
    icon: null,
    iconPosition: 'left',
    label: '',
    onClick: () => {},
    secondIcon: null,
    type: null,
    shouldOpenNewTab: false,
    loadingText: 'Loading...',
};

export default Button;
