export const untilWithTimeout = (
    conditionFunction,
    failureMessage,
    timeout = 10000,
    tick = 100
) => {
    const startTime = Date.now();

    const poll = (resolve, reject) => {
        if (Date.now() - startTime > timeout) {
            reject(failureMessage);
        } else if (conditionFunction()) {
            resolve();
        } else {
            setTimeout((_) => poll(resolve, reject), tick);
        }
    };

    return new Promise(poll);
};
