import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
    mediaQuery,
    breakpoints,
    gutterSizes,
    fontSizes,
    colorVariables,
    colors,
} from '@gumtree/ui-library/src/base/theme';

export const FooterLinksSection = styled.section`
    margin-bottom: ${gutterSizes.large};

    .toggle {
        &.open {
            &.open-transition {
                .content {
                    height: auto;
                }
            }
        }
    }
`;

export const FooterLinksTitle = styled.h4`
    font-size: ${fontSizes.large};
    color: ${colorVariables.fontQuaternary};
    margin: 0 0 ${gutterSizes.base} 0;

    .toggler > .footer-links-title {
        flex-grow: 0;
        padding-right: ${gutterSizes.medium};
    }
`;

export const FooterLinksItemStyle = css`
    color: ${colors.blue40};
    display: block;
    padding: ${gutterSizes.small} 0;
`;

export const FooterLinksDownload = styled.div`
    ${mediaQuery.until(breakpoints.medium)} {
        width: 170px;
        height: 50px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
`;
