import { Button, Input, Link } from '@gumtree/ui-library';
import React, { MutableRefObject, useRef, useState } from 'react';
import validate, { ErrorType, validateTerms } from '@gumtree/register/src/components/validate';
import { postData } from '@gumtree/login/src/post-data';
import {
    CheckboxContainer,
    FormContainer,
    FormTitle,
    GroupedInput,
    PasswordContainer,
    PasswordAdvice,
    PasswordAdviceContainer,
    PasswordStrength,
} from './register-modal.style';
import CheckEmailModal from './check-email-modal';
import logger from '../logger';
import { useModal } from '../use-modal';

export interface RegisterModalProps {
    setShowForm?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterModal = ({ setShowForm }: RegisterModalProps) => {
    const { openDialog } = useModal();
    const formRef = useRef() as MutableRefObject<HTMLFormElement>;
    const [values, setValues] = useState({} as any);
    const [errors, setErrors] = useState({} as ErrorType);
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showChecklist, setShowChecklist] = useState<boolean>(false);
    const [passwordValidated, setPasswordValidated] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [resend, setResend] = useState('');
    const [validationState, setValidationState] = useState({
        lowerCase: { title: 'One lower case letter', validated: false },
        upperCase: { title: 'One upper case letter', validated: false },
        number: { title: 'One number', validated: false },
        specialChar: { title: 'One special character', validated: false },
        minLength: { title: 'Minimum 8 characters', validated: false },
    });

    const { firstname, lastname, username, password, terms = true, offers = true } = values;
    const areValuesEmpty = !firstname || !lastname || !username || !password || !terms;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setValues((values) => ({
            ...values,
            [event.target.id]:
                event.target.id === 'offers' || event.target.id === 'terms'
                    ? event.target.checked
                    : event.target.value,
        }));
        event.target.name === 'form.password' && validatePassword(event.target.value);

        if (event.target.id === 'terms') {
            const newErrors = validateTerms(!event.target.checked);

            setErrors((prevErrors) => {
                if (!newErrors.terms) {
                    const { terms: _unused, ...rest } = prevErrors;
                    return rest;
                }
                return { ...prevErrors, ...newErrors };
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const errors = validate(values, 'all');

        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }

        const formData = {
            'form.firstName': firstname,
            'form.lastName': lastname,
            'form.emailAddress': username,
            'form.password': password,
            'form.optInMarketing': 'on',
            'form.legacy': 'false',
        };

        postData(formData, setIsLoading, '/bff-api/register/via-form-new')
            .then((data) => {
                if (data?.resendPath !== null) {
                    setIsLoading(false);
                    setShowSuccess(true);
                    setResend(data?.resendPath);
                } else {
                    setIsLoading(false);
                    const newErrors = {
                        password: data?.formErrors.password
                            ? data.formErrors.password[0]
                            : undefined,
                        username: data?.formErrors.username
                            ? `${data.formErrors.username[0]}`
                            : undefined,
                    };

                    setErrors(newErrors);
                }
            })
            .catch((e) => {
                logger.error(e);
                setIsLoading(false);
            });
    };

    function validatePassword(password: string) {
        const lowerCasePattern = /[a-z]/;
        const upperCasePattern = /[A-Z]/;
        const numberPattern = /[0-9]/;
        const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
        const minLengthPattern = /.{8,}/;

        const hasLowerCase = lowerCasePattern.test(password);
        const hasUpperCase = upperCasePattern.test(password);
        const hasNumber = numberPattern.test(password);
        const hasSpecialChar = specialCharPattern.test(password);
        const hasMinLength = minLengthPattern.test(password);

        setValidationState({
            lowerCase: { title: 'One lower case letter', validated: hasLowerCase },
            upperCase: { title: 'One upper case letter', validated: hasUpperCase },
            number: { title: 'One number', validated: hasNumber },
            specialChar: { title: 'One special character', validated: hasSpecialChar },
            minLength: { title: 'Minimum 8 characters', validated: hasMinLength },
        });

        setPasswordValidated(
            hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && hasMinLength
        );
    }

    const handleShowPassword = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleBlur = (e) => {
        const id = e.target.id;
        if (e.target.name === 'form.password') {
            setShowChecklist(false);
            validatePassword(e.target.value);
        } else {
            const newErrors = validate(values, id);

            // Remove undefined errors
            const updatedErrors = { ...errors, [id]: newErrors[id] };
            Object.keys(updatedErrors).forEach((key) => {
                if (updatedErrors[key] === undefined) {
                    delete updatedErrors[key];
                }
            });

            setErrors(updatedErrors);
        }
    };

    return (
        <FormContainer>
            {showSuccess ? (
                <CheckEmailModal resendPath={resend} />
            ) : (
                <>
                    <FormTitle>Sign up</FormTitle>
                    <form
                        ref={formRef}
                        className="form"
                        name="registration-form"
                        noValidate
                        method="POST"
                        action="/bff-api/register/via-form-new"
                    >
                        <GroupedInput>
                            <Input
                                labelClassName={errors.firstname && 'error-label'}
                                name="form.firstName"
                                id="firstname"
                                label="First name"
                                altLabel="firstname"
                                autoComplete="off"
                                required
                                hideClear
                                type="text"
                                value={firstname || ''}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                errorMessages={errors.firstname}
                            />
                            <Input
                                labelClassName={errors.lastname && 'error-label'}
                                name="form.lastName"
                                id="lastname"
                                label="Last name"
                                altLabel="lastname"
                                autoComplete="off"
                                required
                                hideClear
                                type="text"
                                value={lastname || ''}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                errorMessages={errors.lastname}
                            />
                        </GroupedInput>
                        <Input
                            labelClassName={errors.username && 'error-label'}
                            name="form.emailAddress"
                            id="username"
                            label="Email address"
                            altLabel="username"
                            autoComplete="off"
                            required
                            type="email"
                            hideClear
                            value={username || ''}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            errorMessages={errors.username}
                            errorAdditionalInfo={
                                errors.username === 'The email address is already registered'
                                    ? {
                                          text: 'log in',
                                          link: () => {
                                              openDialog(
                                                  { preventDefault: () => {} },
                                                  'login',
                                                  false
                                              );
                                              setShowForm && setShowForm(false);
                                          },
                                      }
                                    : undefined
                            }
                        />
                        <PasswordContainer isPasswordVisible={isPasswordVisible}>
                            <Input
                                labelClassName={
                                    password?.length > 0 && !passwordValidated && !showChecklist
                                        ? 'error-label'
                                        : ''
                                }
                                name="form.password"
                                id="password"
                                label="Password"
                                altLabel="password"
                                autoComplete="off"
                                required
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={password || ''}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                onFocus={() => {
                                    setShowChecklist(true);
                                }}
                                errorMessages={errors.password}
                                hideClear
                                revealValueFunction={handleShowPassword}
                                isPasswordVisible={isPasswordVisible}
                            />
                            <PasswordAdviceContainer>
                                {showChecklist && !passwordValidated ? (
                                    <>
                                        {Object.values(validationState).map((el) => (
                                            <PasswordAdvice
                                                data-testid={`${el.title}-${el.validated}`}
                                                isValidated={el.validated}
                                                key={el.title}
                                            >
                                                {el.title}
                                            </PasswordAdvice>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {password?.length > 0 && (
                                            <PasswordStrength isValidated={passwordValidated}>
                                                {passwordValidated
                                                    ? 'Strong password'
                                                    : 'Weak password'}
                                            </PasswordStrength>
                                        )}
                                    </>
                                )}
                            </PasswordAdviceContainer>
                        </PasswordContainer>
                        <CheckboxContainer>
                            <div className="control-group">
                                <label htmlFor="offers" className="control control-checkbox">
                                    I would like to receive emails with offers and the latest
                                    Gumtree news{' '}
                                    <input
                                        type="checkbox"
                                        checked={offers}
                                        id="offers"
                                        onChange={handleInputChange}
                                    />
                                    <div className="control_indicator" />
                                </label>
                                <label htmlFor="terms" className={errors.terms ? 'error-checkbox control control-checkbox' : 'control control-checkbox'}>
                                    I agree to the{' '}
                                    <Link openInNewTab href="/termsofuse">
                                        Terms of Use
                                    </Link>{' '}
                                    and{' '}
                                    <Link openInNewTab href="/privacy_policy">
                                        Privacy Notice
                                    </Link>
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        checked={terms}
                                        onChange={handleInputChange}
                                    />
                                    <div className="control_indicator" />
                                </label>
                                {errors.terms && <span className="error-messages">{errors.terms}</span>}
                            </div>
                        </CheckboxContainer>
                        <Button
                            disabled={Object.keys(errors).length > 0 || areValuesEmpty}
                            type="submit"
                            isLoading={isLoading}
                            display="primary"
                            label="Continue"
                            fullWidth
                            onClick={handleSubmit}
                        />
                    </form>
                </>
            )}
        </FormContainer>
    );
};

export default RegisterModal;
