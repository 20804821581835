 
import Cookies from 'js-cookie';
import { untilWithTimeout } from './until-with-timeout';

let trackQueue: Array<GA4.GA4Event> = [];
let blockTrackQueue = false;

export function legacyPageTypeToGAContentGroup(
    input: GA4.PageType | undefined
): Pick<GA4.PageDeclarationEvent['page'], 'contentGroup' | 'contentSubGroup'> {
    switch (input) {
        case 'Article':
            return { contentGroup: 'article page', contentSubGroup: 'article page' };
        case 'EditAdBumpUpPromo':
            return { contentGroup: 'post ad page', contentSubGroup: 'edit ad bump up page' };
        case 'EditAdBumpUpPromo-AppWebView-ANDROID':
            return {
                contentGroup: 'post ad page',
                contentSubGroup: 'edit ad bump up page app android',
            };
        case 'EditAdBumpUpPromo-AppWebView-IOS':
            return {
                contentGroup: 'post ad page',
                contentSubGroup: 'edit ad bump up page app ios',
            };
        case 'EditorialArticle':
            return { contentGroup: 'editorial page', contentSubGroup: 'editorial page' };
        case 'EditorialIndex':
            return { contentGroup: 'editorial page', contentSubGroup: 'editorial index page' };
        case 'EmailVerification':
            return {
                contentGroup: 'email verification page',
                contentSubGroup: 'email verification page',
            };
        case 'Error_404':
            return { contentGroup: 'error page', contentSubGroup: 'error 404 page' };
        case 'Error_500':
            return { contentGroup: 'error page', contentSubGroup: 'error 500 page' };
        case 'Favourites':
            return { contentGroup: 'favourites page', contentSubGroup: 'favourites page' };
        case 'Homepage':
            return { contentGroup: 'homepage', contentSubGroup: 'homepage' };
        case 'LandingPageCategory':
            return { contentGroup: 'seo landing page', contentSubGroup: 'hidden seo page' };
        case 'LandingPageCars':
            return { contentGroup: 'seo landing page', contentSubGroup: 'cars landing page' };
        case 'LandingPageCarsSelling':
            return {
                contentGroup: 'seo landing page',
                contentSubGroup: 'sell my car landing page',
            };
        case 'LandingPageJobs':
            return { contentGroup: 'jobs landing page', contentSubGroup: 'jobs landing page' };
        case 'LegalCookie':
            return { contentGroup: 'legal info page', contentSubGroup: 'listing page' };
        case 'LegalPrivacy':
            return { contentGroup: 'legal info page', contentSubGroup: 'legal privacy page' };
        case 'LegalTC':
            return { contentGroup: 'legal info page', contentSubGroup: 'legal t&c page' };
        case 'Login':
            return { contentGroup: 'login page', contentSubGroup: 'login page' };
        case 'Logout':
            return { contentGroup: 'logout page', contentSubGroup: 'logout page' };
        case 'ManageAds':
        case 'MyAds':
            return { contentGroup: 'manage ads page', contentSubGroup: 'manage ads page' };
        case 'ManageAdsPro':
            return { contentGroup: 'manage ads page', contentSubGroup: 'manage ads pro page' };
        case 'MyAccount':
            return { contentGroup: 'my account page', contentSubGroup: 'my account page' };
        case 'MyMessages':
            return { contentGroup: 'chat page', contentSubGroup: 'chat page' };
        case 'OrderReview':
            return { contentGroup: 'order review page', contentSubGroup: 'order review page' };
        case 'OrderReview-AppWebView-ANDROID':
            return {
                contentGroup: 'order review page',
                contentSubGroup: 'order review page android',
            };
        case 'OrderReview-AppWebView-IOS':
            return { contentGroup: 'order review page', contentSubGroup: 'order review page ios' };
        case 'OrderSuccess':
            return { contentGroup: 'success page', contentSubGroup: 'order success page' };
        case 'Other':
            return { contentGroup: 'other page', contentSubGroup: 'other page' };
        case 'PasswordReset':
            return { contentGroup: 'password reset page', contentSubGroup: 'password reset page' };
        case 'PasswordResetSent':
            return {
                contentGroup: 'password reset page',
                contentSubGroup: 'password reset sent page',
            };
        case 'PaymentSuccess':
            return { contentGroup: 'success page', contentSubGroup: 'payment success page' };
        case 'PostAd': // legacy
        case 'PostAdCreate':
            return { contentGroup: 'post ad page', contentSubGroup: 'post ad page' };
        case 'PostAdCreate-AppWebView-ANDROID':
            return { contentGroup: 'post ad page', contentSubGroup: 'post ad page app android' };
        case 'PostAdCreate-AppWebView-IOS':
            return { contentGroup: 'post ad page', contentSubGroup: 'post ad page app ios' };
        case 'PostAdCategorySelect':
            return { contentGroup: 'post ad page', contentSubGroup: 'select category page' };
        case 'PostAdCategorySelect-AppWebView-ANDROID':
            return {
                contentGroup: 'post ad page',
                contentSubGroup: 'select category page app android',
            };
        case 'PostAdCategorySelect-AppWebView-IOS':
            return {
                contentGroup: 'post ad page',
                contentSubGroup: 'select category page app ios',
            };
        case 'EditAd': // legacy
        case 'PostAdEdit':
            return { contentGroup: 'edit ad page', contentSubGroup: 'edit ad page' };
        case 'PostAdEdit-AppWebView-ANDROID':
            return { contentGroup: 'edit ad page', contentSubGroup: 'edit ad page app android' };
        case 'PostAdEdit-AppWebView-IOS':
            return { contentGroup: 'edit ad page', contentSubGroup: 'edit ad page app ios' };
        case 'PostAdPromote':
            return { contentGroup: 'promote ad page', contentSubGroup: 'promote ad page' };
        case 'PostAdPromote-AppWebView-ANDROID':
            return {
                contentGroup: 'promote ad page',
                contentSubGroup: 'promote ad page app android',
            };
        case 'PostAdPromote-AppWebView-IOS':
            return { contentGroup: 'promote ad page', contentSubGroup: 'promote ad page app ios' };
        case 'PostAdRepost':
            return { contentGroup: 'relist ad page', contentSubGroup: 'relist ad page' };
        case 'PostAdRepost-AppWebView-ANDROID':
            return {
                contentGroup: 'relist ad page',
                contentSubGroup: 'relist ad page app android',
            };
        case 'PostAdRepost-AppWebView-IOS':
            return { contentGroup: 'relist ad page', contentSubGroup: 'relist ad page app ios' };
        case 'Profile':
            return { contentGroup: 'user profile page', contentSubGroup: 'user profile page' };
        case 'R2SEmail':
            return { contentGroup: 'message seller page', contentSubGroup: 'message seller page' };
        case 'R2SEmailSuccess':
            return { contentGroup: 'success page', contentSubGroup: 'email success page' };
        case 'ResultsBrowse':
            return { contentGroup: 'browse results page', contentSubGroup: 'browse results page' };
        case 'ResultsSearch':
            return { contentGroup: 'search results page', contentSubGroup: 'search results page' };
        case 'ResultsSeller':
            return { contentGroup: 'seller ads page', contentSubGroup: 'seller ads page' };
        case 'SavedSearches':
            return { contentGroup: 'saved searches page', contentSubGroup: 'saved searches page' };
        case 'SessionExpired':
            return {
                contentGroup: 'session expired page',
                contentSubGroup: 'session expired page',
            };
        case 'UserActivationSuccess':
            return {
                contentGroup: 'success page',
                contentSubGroup: 'user activation success page',
            };
        case 'UserRegistrationForm':
            return {
                contentGroup: 'user registration page',
                contentSubGroup: 'user registration page',
            };
        case 'UserRegistrationSuccess':
            return {
                contentGroup: 'success page',
                contentSubGroup: 'user registration success page',
            };
        case 'VehicleVerification':
            return {
                contentGroup: 'vehicle verification page',
                contentSubGroup: 'vehicle verification page',
            };
        case 'VIP':
            return { contentGroup: 'listing page', contentSubGroup: 'listing page' };

        case 'DALandingPage':
        case 'VipGallery':
        case 'eVIP':
        case 'pVIP':
        case 'UserStatic':
        case undefined:
            return { contentGroup: 'unknown page', contentSubGroup: 'unknown page' };
        default: // type constraint
            testNever(input);
            return { contentGroup: 'unknown page', contentSubGroup: 'unknown page' };
    }
}

export function trackGA4Event<T extends GA4.GA4Event = GA4.GA4Event>(event: T) {
    window.gumtreeDataLayer.push(event);
}

/**
 * @param {number} timeoutMS Default `1000`
 */
export async function trackGA4Async<T extends GA4.GA4Event = GA4.GA4Event>(
    event: T,
    timeoutMS = 1000
) {
    (await new Promise((resolve) => {
        trackGA4Event({
            ...event,
            eventCallback: () => resolve(true),
        }); // in case GTM unresponsive:
        setTimeout(() => resolve(false), timeoutMS);
         
    })) || console.debug('trackGA4Async: timed out', event);
}

export async function trackGA4AfterConsent<T extends GA4.GA4Event = GA4.GA4Event>(event: T) {
    if (hasUserConsentedToPerformanceTracking() && trackQueue.length === 0) {
        trackGA4Async(event);
    } else {
        const queueNeedsInitialised = trackQueue.length === 0;

        if (!blockTrackQueue) {
            trackQueue.push(event);
        }

        if (queueNeedsInitialised) {
            processGaSyncQueue();
        }
    }
}

function hasUserConsentedToPerformanceTracking() {
    const hasUserConsented =
        window.gumtreeDataLayer &&
        !!window.gumtreeDataLayer.find(
            (x) => x?.event === 'OneTrustGroupsUpdated' && x.OnetrustActiveGroups.includes('C0002')
        );

    return hasUserConsented;
}

async function processGaSyncQueue() {
    try {
        await untilWithTimeout(
            hasUserConsentedToPerformanceTracking,
            'Could not find user consent for performance tracking'
        );

        while (trackQueue.length > 0) {
            await trackGA4Async(trackQueue[0]);
            trackQueue = trackQueue.slice(1);
        }
    } catch (_e) {
        blockTrackQueue = true;
    }
}

export async function trackGA4AndNavigate<T extends GA4.GA4Event = GA4.GA4Event>(
    input: TrackGA4AndNavigateParam<T>
) {
    input.mouseEvent.preventDefault();
    await trackGA4Async(input.payload);
    if (input.mouseEvent.metaKey) {
        window.open(input.href, '_blank');
    } else {
        window.location.href = input.href;
    }
}

function testNever(_x: never) {
    return true;
}

export interface TrackGA4AndNavigateParam<T extends GA4.GA4Event = GA4.GA4Event> {
    href: string;
    payload: T;
    mouseEvent: MouseEvent | React.MouseEvent;
}

/** Browser only */
export function setLoginBeginCookie(value: LoginBeginCookieValue) {
    Cookies.set('LoginBegin', value, {
        domain: getIndicatorCookieDomain(),
        expires: 1 / 48, // 30 minutes from now
    });
}

/** Browser only */
export function setSocialRegistrationBeginCookie(value: LoginBeginCookieValue) {
    Cookies.set('SocialRegistrationBegin', value, {
        domain: getIndicatorCookieDomain(),
        expires: 1 / 48, // 30 minutes from now
    });
}

/**
 * This domain must be higher than both `www.foo` and `my.foo` to handle:
 * - Legacy Login -> New Manage Ads
 * - New Login -> New Manage Ads
 * - New Login -> Legacy Manage Ads
 */
function getIndicatorCookieDomain() {
    const { hostname } = window.location;
    if (hostname.endsWith('.gumtree.com')) {
        return 'gumtree.com';
    } else if (hostname.endsWith('.gumtree.io')) {
        // treat *.staging.gumtree.io same as dev.gumtree.io e.g.
        // because of proxy-seller.thirdparty.staging.gumtree.io
        return 'gumtree.io';
    } else {
        return hostname;
    }
}

export function trackGA4UserLoginSuccess() {
    const loginBegin = Cookies.get('LoginBegin');

    if (isLoginBeginValue(loginBegin)) {
        trackGA4Event<GA4.UserLoginEvent>({
            event: 'user_login',
            formName: 'user login',
            formStep: 'user login',
            formValidation: 'success',
            userAccountType: loginBeginValueToAccountType[loginBegin],
        });
        Cookies.remove('LoginBegin', { domain: getIndicatorCookieDomain() });
    }
}

export function trackGA4UserRegistrationSuccess() {
    const socialRegistrationBegin = Cookies.get('SocialRegistrationBegin');

    if (isLoginBeginValue(socialRegistrationBegin)) {
        trackGA4Event<GA4.UserLoginRegisterEvent>({
            event: 'user_registration',
            formName: 'user registration',
            formStep: 'user registration',
            formValidation: 'success',
            userAccountType: loginBeginValueToAccountType[socialRegistrationBegin],
        });
        Cookies.remove('LoginBegin', { domain: getIndicatorCookieDomain() });
    }
}

function isLoginBeginValue(input?: string): input is LoginBeginCookieValue {
    return input !== undefined && input in loginBeginValueToAccountType;
}

/** These keys must match those set by legacy login/registration page */
const loginBeginValueToAccountType = {
    LoginBegin: 'email',
    googleplus: 'google',
    facebook: 'facebook',
} as const;

export type LoginBeginCookieValue = keyof typeof loginBeginValueToAccountType;
