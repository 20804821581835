import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
    mediaQuery,
    gutterSizes,
    breakpoints,
    colorVariables,
    colors,
    fontSizes,
} from '@gumtree/ui-library/src/base/theme';

export const FooterBlock = styled.footer`
    background-color: ${colorVariables.mainSecondary};

    .container {
        padding-bottom: ${gutterSizes.xlarge} ${mediaQuery.from(breakpoints.medium)} {
            padding-bottom: ${gutterSizes.xxlarge};
        }
    }
`;

export const FooterAppLinksWrapper = styled.div`
    margin-bottom: 15px;
`;

export const FooterLinkTitle = styled.h4`
    font-size: ${fontSizes.large};
    color: ${colors.bark40};
    margin: 0 0 ${gutterSizes.base};
`;

export const FooterDividerStyle = css`
    border: none;
    height: 2px;
    background-color: ${colorVariables.borderDark};
    margin: 0 0 ${gutterSizes.large};
`;
