import React from 'react';
import { css } from '@emotion/react';
import {
    colors,
    fontSizes,
    gutterSizes,
    lineHeights,
    mediaQuery,
    zIndexes,
} from '@gumtree/ui-library/src/base/theme';

import backgroundImage from '../common/armchair.jpg';
import curveVerticalImage from '../common/curve-vertical.png';
import curveHorizontalImage from '../common/curve-horizontal.png';
import Sticker from '../common/good-find-sticker';
import GumtreeLogo from '../common/gumtree-logo';
import GumtreeMiniLogo from '../common/gumtree-logo-mini';

import {
    armchairStyles,
    commonCurveVerticalStyles,
    commonTitleStyles,
    layoutBoundary,
} from '../common/common.style';

export { StyledLocationField, AlternativeText } from '../common/common.style';

export const Overlay = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: ${zIndexes.high + 10};
        background: ${colors.blade};
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const Header = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        padding: 8px 24px 8px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const Logo = () => {
    const smallScreenStyles = css`
        ${mediaQuery.from(layoutBoundary)} {
            display: none;
        }
    `;

    const largeScreenStyles = css`
        ${mediaQuery.until(layoutBoundary)} {
            display: none;
        }
    `;

    return (
        <a href="/">
            <div css={smallScreenStyles}>
                <GumtreeMiniLogo />
            </div>
            <div css={largeScreenStyles}>
                <GumtreeLogo />
            </div>
        </a>
    );
};

export const ButtonGroup = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css``;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const ContentWrapper = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        flex-grow: 1; /* Expand to fill the available space */
        display: flex;

        ${mediaQuery.until(layoutBoundary)} {
            flex-direction: column;
        }
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const Content = ({ children, ...props }: React.PropsWithChildren) => {
    const outerStyles = css`
        display: flex;
        justify-content: center;
        align-items: center;

        ${mediaQuery.from(layoutBoundary)} {
            width: 50vw;
        }
        ${mediaQuery.until(layoutBoundary)} {
            max-width: 350px;
        }
    `;

    const innerStyles = css`
        max-width: 500px;

        ${mediaQuery.until(layoutBoundary)} {
            padding-top: ${gutterSizes.xxlarge};
            padding-left: ${gutterSizes.large};
            padding-right: ${gutterSizes.large};
        }

        ${mediaQuery.from(layoutBoundary)} {
            padding: 0 30px 140px;
        }
    `;

    return (
        <div css={outerStyles} {...props}>
            <div css={innerStyles}>{children}</div>
        </div>
    );
};

export const BackgroundImage = () => {
    const containerStyles = css`
        position: relative;

        ${mediaQuery.until(layoutBoundary)} {
            width: 100%;
            flex-grow: 1; /* Expand to fill the available space */
        }

        ${mediaQuery.from(layoutBoundary)} {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 50vw;
            height: 100vh;
        }
    `;

    const curveVerticalStyles = css`
        width: 300px;
    `;

    const curveHorizontalStyles = css`
        ${mediaQuery.from(layoutBoundary)} {
            display: none;
        }

        position: absolute;
        width: 100vw;
        height: 150px;
        top: 0;
        left: 0;
    `;

    const stickerStyles = css`
        position: absolute;
        top: 32%;
        right: 10%;

        ${mediaQuery.until(layoutBoundary)} {
            transform: scale(0.7);
            right: 8%;
        }
    `;

    return (
        <div css={containerStyles}>
            <img css={armchairStyles} src={backgroundImage} alt="background" />
            <img
                css={css(commonCurveVerticalStyles, curveVerticalStyles)}
                src={curveVerticalImage}
                alt="vertical curve"
            />
            <img css={curveHorizontalStyles} src={curveHorizontalImage} alt="vertical curve" />
            <Sticker css={stickerStyles} />
        </div>
    );
};

export const Title = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        font-size: ${fontSizes.iconLarge};
        line-height: ${lineHeights.xxlarge};

        ${mediaQuery.until(layoutBoundary)} {
            font-size: ${fontSizes.pgLarge};
            line-height: ${lineHeights.xlarge};
        }
    `;

    return (
        <h1 css={css(commonTitleStyles, styles)} {...props}>
            {children}
        </h1>
    );
};
